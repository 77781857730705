.footer {
    height: 4rem;
    margin-top: 2rem;
    text-align: center;
}

.footer-content {
    padding-top: 1rem;
}

.footer-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer-text {
    margin: 0;
}

.footer-text {

}
