/* Change the track color */
.rc-slider-track {
    background-color: #0D6EFD !important;
}

/* Change the handle color */
.rc-slider-handle {
    border-color: #0D6EFD !important;
    background-color: #ffffff !important;
}

/* Change the rail color */
.rc-slider-rail {
    background-color: #ddd !important;
}

.armor-optimisation-density-selection {
    max-width: 100%;
}

.armor-optimisation-density-selection-button {
    padding: 0.1rem 0.4rem;
    max-width: 100%;
}

.armor-optimisation-card-list-display {
    margin-top: 0.5rem;
    margin-bottom: 0;
}

.armor-optimisation-slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.armor-optimisation-small-padding {
    padding: 0.1rem;
}

.armor-optimisation-button-text {
    font-weight: bold;
}

.armor-optimisation-setting-button {
    margin-right: 0.2rem;
}

.armor-optimisation-chart {
    padding: 0;
    text-align: center;
}

.armor-optimisation-display-center {
    padding-top: 1rem;
    text-align: center;
}

.armor-optimisation-margin {
    margin-top: 1rem;
}

.armor-optimisation-text-align-center {
    text-align: center;
}

