:root {
    --background-color: white;
    --card-background: white;
    --footer-background: #f8f9fa;
    --header-background: #f8f9fa;
    --text-color: black;
    --apex-chart-text-color: black;
}

body.light {
    --background-color: white;
    --card-background: white;
    --footer-background: #f8f9fa;
    --header-background: #f8f9fa;
    --text-color: black;
    --apex-chart-text-color: black;
}

body.dark {
    --background-color: #343a40;
    --card-background: #343a40;
    --footer-background: #343a40;
    --header-background: #2b2b2b;
    --text-color: white;
    --apex-chart-text-color: white;
}

body {
    background-color: var(--background-color, white);
    color: var(--text-color, black);
}

.navbar {
    background-color: var(--header-background, white);
    color: var(--text-color, white);
}

.nav-link {
    background-color: var(--header-background, white);
    color: var(--text-color, white);
}

.nav-link:hover {
    background-color: var(--header-background, white);
    color: var(--text-color, white);
}

.navbar-toggler {
    border-color: var(--text-color, white);
    color: var(--text-color, white);
}

.footer-container {
    background-color: var(--header-background, white);
    color: var(--text-color, white);
}

.card-header {
    background-color: var(--header-background, white);
    color: var(--text-color, white);
}

.card-body {
    background-color: var(--background-color, white);
    color: var(--text-color, black);
}

.card-attribute-input {
    background-color: var(--background-color, white);
    color: var(--text-color, black);
}

.apexcharts-text {
    color: var(--apex-chart-text-color, black) !important;
}

.apexcharts-legend-text {
    color: var(--apex-chart-text-color, black) !important;
}

.apexcharts-text .apexcharts-yaxis-label {
    color: var(--apex-chart-text-color, black) !important;
}

.apexcharts-text .apexcharts-yaxis-title-text {
    color: var(--apex-chart-text-color, black) !important;
}

.apexcharts-text .apexcharts-xaxis-label {
    color: var(--apex-chart-text-color, black) !important;
}

.apexcharts-text .apexcharts-xaxis-title-text {
    color: var(--apex-chart-text-color, black) !important;
}
