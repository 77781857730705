.homepage-text {
    font-size: 1.2rem;
    font-weight: 700;
    color: #fff;
    text-shadow:
            0 0 1.2rem #000,
            0 0 0.4rem #000,
            3px 3px 5px rgba(0, 0, 0, 0.8);
    margin: 0;
    padding: 0;
}

.homepage-title {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    text-shadow:
            0 0 1.2rem #000,
            0 0 0.2rem #000,
            3px 3px 5px rgba(0, 0, 0, 0.8);
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.homepage-bottom-text {
    padding-top: 30rem;
}

.homepage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
}

.homepage-discord-invite-button {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #5865F2;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s;
    box-shadow: none;
    border: none;
}

.homepage-discord-invite-button:hover,
.homepage-discord-invite-button:focus {
    outline: none;
    border: none;
    background-color: #4752c4;
    text-decoration: none;
    color: #ffffff;
}
