
.value-table-image-td {
    padding: 0.4rem !important;
    text-align: center;
}

.value-table-image {
    max-height: 3rem;
    max-width: 3rem;
}
