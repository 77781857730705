.navbar {
    padding-left: 3rem;
    padding-right: 2rem;
}

.social-links .nav-link {
    padding: 0.3rem 0.3rem !important;
}

.fab {
    font-size: 1.2rem;
    color: #4A4A4B;
}

.social-links {
    display: flex;
    gap: 0.8rem;
}

.social-links img {
    height: 1.2rem;
    width: auto;
    cursor: pointer;
    margin-top: 0.4rem;
}

.theme-button {
    height: 1.2rem;
    width: auto;
    cursor: pointer;
    vertical-align: center;
    margin-top: 0.25rem;
}

.navbar-brand {
    margin-right: 0;
    margin-left: 0.2rem;
}

.logo {
    max-height: 3rem;
}

.header-navlink {
    padding: 0.2rem;
    margin-left: 0.5rem;
    min-width: 10rem;
}

@media (max-width: 991px) {
    .ml-auto {
        margin-left: auto !important;
    }

    .nav-links-container {
        margin-left: 0.2rem;
    }
}

@media (min-width: 992px) {
    .social-links {
        margin-left: auto;
    }

    .nav-links-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }
}
