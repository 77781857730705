
.ratio_chart_value_toggle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ratio_chart_value_text {
  font-size: 0.9rem;
}
