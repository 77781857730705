
.gen-density-selection {
    margin-bottom: 0.8rem;
}

.gen-density-selection-title {
    font-size: medium;
}

.gen-density-selection-button {
    height: 2rem;
    text-align: center;
    vertical-align: center;
    padding: 0;
}

.gen-bat-text {
}

.gen-bat-input {
}
