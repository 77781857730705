
.thruster-density-selection {
    max-width: 100%;
}

.thruster-density-selection-button {
    padding: 0.1rem 0.4rem;
    max-width: 100%;
}

.thruster-card-list-display {
    margin-top: 0.5rem;
    margin-bottom: 0;
}
