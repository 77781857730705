.values-title {
    text-align: center;
}

.values-bold {
    font-weight: bold;
}

.values-container {
}

.values-row {
    margin-top: 1rem;
}

.values-discord-button {
    max-width: 12rem;
}
