
.stats-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stats-card {
    margin-bottom: 0.5rem;
}

.stats-toggle {
    text-align: right;
}

.stats-container {
}

.stats-sub-title {
    padding: 0;
}

.stats-text {
    padding: 0;
}

.stats-text-numbers {
    padding: 0 0.5rem 0 0;
    font-weight: bold;
}

.stats-bold {
    font-weight: bold;
}

.stats-error {
    padding: 0;
    font-weight: bold;
    color: #ff0000;
}

.stats-warning {
    padding: 0;
    font-weight: bold;
    color: #ff6a00;
}

.stats-warning-numbers {
    font-weight: bold;
    color: #ff6a00;
}
