
.calc-setting-button {
    margin-right: 0.2rem;
}

.calc-buttons-container {
    padding-top: 1rem;
}

.calc-title-container {
    text-align: center;
    display: block;
    padding-bottom: 1rem;
}

.calc-title-title-row {
}

.calc-container {
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 100%;
}

.calc-button-icon {
    padding-right: 0.2rem;
}

.calc-button-text {
    font-weight: bold;
}

.calc-notification {
    margin: 0;
    height: 2rem;
    padding-top: 1rem;
}