
.thruster-optimisation-warning {
    padding: 0;
    font-weight: bold;
    color: #ff6a00;
}

.thruster-optimisation-chart {
    padding: 0;
}

.chart_list_display {
    margin-left: 0.5rem;
}
