.card {
    margin-top: 1rem;
}

.card-body {
    padding: 0.4rem 0.7rem;
}

.card-body-container {
    padding: 0;
}

.card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
}

.card-title-text {
    text-align: left;
    margin: 0;
}

.card-close {
    text-align: right;
}

.card-close-button {
    padding: 0 0.3rem;
}

.card-attribute {
    align-items: center;
    max-height: 2rem;
}

.card-attribute-text {
    line-height: 0.9rem;
}

.card-attribute-input {
    margin: 0.1rem;
    max-width: 100%;
}

.card-attribute-input-select {
    min-width: 5rem;
    max-height: 2rem;
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;
}
